<template>
  <div :ref="id" class="drag-container card" :class="dragged ? 'dragged' : ''">
    <div @mousedown="dragMouseDown" class="drag-container-header card-header">
      <slot name="header"></slot>
      <i class="fad fa-arrows drag-icon"></i>
    </div>
    <div class="drag-container-body card-body p-0" :style="'height:' + (showBody ? maxHeight || '40vh' : '0')">
      <slot name="main"></slot>
    </div>
    <div class="drag-container-footer card-footer p-0 text-center clickable">
      <button @click="showBody = !showBody" class="btn btn-light btn-block btn-sm">
        <i class="fas" :class="!dragged ^ showBody ? 'fa-caret-up' : 'fa-caret-down'"></i>
      </button>
    </div>
    <!--<div v-if="showDock" class="drag-container-dock bg-dark rounded" :style="dockPositions">
      <i class="fad fa-check-circle fa-2x text-primary"></i>
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'DragContainer',
  props: {
    maxHeight: String,
    startTop: String,
    startLeft: String,
  },
  data() {
    return {
      id: this.generateId(),
      showBody: false,
      dragged: false,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      showDock: false,
      dockPositions: {
        bottom: undefined,
        left: undefined,
        width: undefined,
        height: undefined
      },
    }
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault()

      this.showDock = true

      // set initial position
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.dragged = true

      // get position delta
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY

      // update old position
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY

      // set new position
      const newLeft = this.$refs[this.id].offsetLeft - this.positions.movementX
      this.$refs[this.id].style.left = newLeft + 'px'
      const newTop = this.$refs[this.id].offsetTop - this.positions.movementY
      this.$refs[this.id].style.top = newTop + 'px'
    },
    closeDragElement () {
      this.showDock = false
      document.onmouseup = null
      document.onmousemove = null

      let isInDock = this.positions.clientY > window.innerHeight - this.dockPositions.height + 10
      if (isInDock) {
        this.dragged = false
        this.$refs[this.id].style.left = this.dockPositions.left + 'px'
        this.$refs[this.id].style.bottom = this.dockPositions.bottom + 'px'
        this.$refs[this.id].style.top = 'unset'

      }
    },
  },
  mounted() {
    if (this.startTop) {
      this.$refs[this.id].style.top = this.startTop
    }
    if (this.startLeft) {
      this.$refs[this.id].style.left = this.startLeft
    }
    this.dockPositions.left = this.$refs[this.id].offsetLeft
    this.dockPositions.top = this.$refs[this.id].offsetTop
    this.dockPositions.height = this.$refs[this.id].offsetHeight
    this.dockPositions.width = this.$refs[this.id].offsetWidth
  }
}
</script>

<style lang="scss" scoped>
.drag-container {
  position: fixed;
  z-index: 9;
  box-shadow: 0 1rem 3rem 0 rgba(0,0,0,.3);
  left: .5rem;
  bottom: .5rem;

  &.dragged {
    bottom: unset;
  }
}
.drag-container-header, .drag-container-footer {
  z-index: 10;
}
.drag-container-body {
  transition: height .2s;
  overflow-y: scroll;
}
.drag-icon {
  position: absolute;
  top: .5rem;
  right: .5rem;
  color: rgba(0,0,0,.3);
  cursor: move;
}

.drag-container-dock {
  opacity: .3;
  text-align: center;
}
</style>
