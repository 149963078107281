<template>
  <div id="audits-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/audits" class="btn btn-secondary mb-5 mr-3">Zurück zur Übersicht</router-link>
    <button @click="step = 0" class="btn btn-secondary mb-5 mr-3">Daten bearbeiten</button>
    <button @click="save" class="btn btn-primary mb-5">Audit speichern</button>

    <div class="row justify-content-center mb-4">
      <div v-if="step >= 0" class="col-sm-11 col-md-7">
        <EditProgress :steps="editSteps" :step-index="step" @setStep="step = $event" :skippable="true"></EditProgress>
      </div>
    </div>

    <div v-if="step === 0" class="card">
      <div class="card-header">
        <h3>Dein neuer Audit</h3>
      </div>
      <div class="card-body">
        <p>
          Bearbeite hier die Daten deines Audits.
        </p>

        <div class="form-group">
          <label>Namen des Audits:</label>
          <input type="text" v-model="newAudit.title" class="form-control">
        </div>

        <div class="form-group">
          <label>Zu welcher Theorie gehört dein Audit?</label>
          <select v-model="newAudit.theoryId" class="form-control mb-3">
            <option value="">Keine Theorie</option>
            <option v-for="(theory, index) in project.theories"
                    :value="theory.id"
                    :selected="theory.id === activeTheoryId"
                    :key="index"
            >
              {{ theory.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <button @click="saveData" class="btn btn-block btn-primary">Audit durchführen</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step === 1" class="card">
      <div class="card-header">
        <h3>{{ newAudit.title }}</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div :class="funnelValues ? 'col-lg-8' : 'col'">
            <div class="form-group">
              <h4>Notizen / Fazit:</h4>
              <textarea v-model="newAudit.summary"
                        placeholder="Schreibe deine Notizen hier auf oder fasse den Audit zusammen.."
                        rows="4"
                        class="form-control mb-3"></textarea>
            </div>

            <div v-if="auditSteps.length > 0" class="border rounded p-3">
              <div class="row flex-nowrap scroll-container-h pt-3">
                <div v-for="(step, index) in auditSteps"
                     @click="selectStep(index)"
                     class="col-4 ml-2"
                     :key="index"
                >
                  <div v-if="step.node" class="card h-100" :class="selectedStep === step ? 'border-primary' : ''">
                    <div class="order-indicator">
                      {{ index+1 }}
                    </div>
                    <div class="card-img-top">
                      <img :src="getImg(step.node)" :alt="step.node.title" class="img-fluid">
                    </div>
                    <div class="card-body">
                      <h4 v-if="auditJourney">{{ auditJourney.steps[index].name }}</h4>
                      <p>{{ step.node.title }}</p>
                      <p class="mb-0">
                        {{ step.node.pagetype }}
                      </p>
                    </div>
                    <div class="card-footer">
                      <p class="mb-0">
                        ({{ step.findings.length }} Findings)
                        <Tooltip><code>{{ step.node.path || step.node.url }}</code></Tooltip>
                      </p>
                    </div>
                    <div v-if="!newAudit.journeyId" @click="removeStep(step.nodeUrl)" class="remove-button">
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="funnelValues" class="col-lg-4 order-2">
            <Funnel :values="funnelValues"
                    :tooltips="funnelTooltips"
                    :height="120"
                    :full-width="true"
                    :show-values="true"
            ></Funnel>
            <div class="btn-group btn-block mt-3">
              <button v-for="(metric, index) in metrics"
                      @click="selectedMetric = metric"
                      class="btn"
                      :class="selectedMetric.id === metric.id ? 'btn-primary' : 'btn-secondary'"
                      :key="index"
              >
                {{ metric.title }}
              </button>
            </div>
          </div>
        </div>

        <h4 class="mt-4">{{ selectedStep.name }}</h4>
        <div class="card">
          <div v-if="stepJourney" class="card-body border-bottom">
            <div>
              <h5>{{ stepJourney.name }}</h5>
              <p>{{ stepJourney.description }}</p>
            </div>
          </div>
          <div v-if="stepNode" class="card-body">

            <h5>{{ stepNode.title }}</h5>
            <p><code>{{ stepNode.path || stepNode.url }}</code></p>
            <p>{{ stepNode.pagetype }}</p>

            <button @click="extendedView = !extendedView" class="btn btn-light btn-block btn-sm">
              {{ extendedView ? 'Weniger' : 'Mehr' }} Details <i class="fal" :class="extendedView ? 'fa-caret-up' : 'fa-caret-down'"></i>
            </button>
            <div v-if="extendedView">
              <NodeEditor :node="stepNode" class="mt-3"></NodeEditor>
            </div>

            <hr>

            <div class="row">
              <div class="col order-2 order-md-1 findings-image">
                <div class="btn-group btn-block mb-4">
                  <button @click="size = 'desktop'" class="btn" :class="size === 'desktop' ? 'btn-primary' : 'btn-light'">Desktop</button>
                  <button @click="size = 'tablet'" class="btn" :class="size === 'tablet' ? 'btn-primary' : 'btn-light'">Tablet</button>
                  <button @click="size = 'mobile'" class="btn" :class="size === 'mobile' ? 'btn-primary' : 'btn-light'">Mobile</button>
                </div>

                <FindingsImage :adding="true"
                               :node="stepNode"
                               :size="size"
                               :findings="stepFindings"
                               :theoryId="newAudit.theoryId"
                               @addFinding="addFinding($event)"
                               @editFinding="editFinding($event)"
                               @removeFinding="removeFinding($event)"
                               @findingEnter="highlightFinding($event, true)"
                               @findingLeave="highlightFinding($event, false)"
                >
                </FindingsImage>
              </div>
              <div class="col-md-4 order-1 order-md-2 d-block d-lg-none">
                <div class="card-body p-1">
                  <div v-if="personas.length">
                    <h5>Personas</h5>
                    <ul class="list-group">
                      <li v-for="(persona, index) in personas" class="list-group-item" :key="index">
                        <h6>{{ persona.title }}</h6>
                        <p class="mb-0">{{ persona.gains.join(", ") }}</p>
                        <p class="mb-0">{{ persona.pains.join(", ") }}</p>
                        <LimbicMap :types="[persona.limbic]" :small="true"></LimbicMap>
                      </li>
                    </ul>
                  </div>

                  <div v-if="activeTheoryId">
                    <h5 class="mt-3">{{ activeTheory.title }}</h5>
                    <p class="mb-0">{{ activeTheory.description }}</p>
                  </div>

                  <h5 class="mt-3">Findings</h5>
                  <FindingsList :findings="stepFindings.filter(f => !!f)"
                                :highlighted-finding-id="highlightedFindingId"
                                :size="size"
                                @addFinding="addFinding($event)"
                                @editFinding="editFinding($event)"
                                @removeFinding="removeFinding($event)"
                  ></FindingsList>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button @click="step = 0" class="btn btn-block btn-secondary">Daten bearbeiten</button>
          </div>
          <div class="col-sm-6">
            <button v-if="step === 1" @click="save" class="btn btn-block btn-success">Audit speichern</button>
          </div>
        </div>
      </div>
    </div>

    <DragContainer v-if="step > 0 && stepFindings" max-height="40vh" class="drag-container d-none d-lg-block">
      <template slot="header">
        <h5 class="m-0">Findings ({{ stepFindings.length }})</h5>
      </template>
      <template slot="main">
        <div class="drag-container-body">
          <FindingsList :findings="stepFindings.filter(f => !!f)"
                        :highlighted-finding-id="highlightedFindingId"
                        :reduced="true"
                        :size="size"
                        @addFinding="addFinding($event)"
                        @editFinding="editFinding($event)"
                        @removeFinding="removeFinding($event)"
          ></FindingsList>
        </div>
      </template>
    </DragContainer>

    <DragContainer v-if="step > 0 && personas.length" max-height="40vh" class="drag-container d-none d-lg-block" start-left="270px">
      <template slot="header">
        <h5 class="m-0">Personas</h5>
      </template>
      <template slot="main">
        <div class="drag-container-body">
          <ul class="list-group">
            <li v-for="(persona, index) in personas" class="list-group-item" :key="index">
              <h6>{{ persona.title }}</h6>
              <strong class="">Haupt-Jobs</strong>
              <ul class="">
                <li v-for="(job, index) in persona.jobs" :key="index">{{ job.title }}</li>
              </ul>
              <strong class="">Haupt-Vorteile</strong>
              <ul v-if="persona.gains.length > 0" class="">
                <li v-for="(gain, index) in persona.gains" :key="index">{{ gain }}</li>
              </ul>
              <strong class="">Haupt-Bedenken</strong>
              <ul v-if="persona.pains.length > 0" class="">
                <li v-for="(pain, index) in persona.pains" :key="index">{{ pain }}</li>
              </ul>
              <LimbicMap :types="[persona.limbic]" :small="true"></LimbicMap>
            </li>
          </ul>
        </div>
      </template>
    </DragContainer>

    <DragContainer v-if="step > 0 && activeTheoryId" max-height="40vh" class="drag-container d-none d-lg-block" start-left="520px">
      <template slot="header">
        <h5 class="m-0">{{ activeTheory.title }}</h5>
      </template>
      <template slot="main">
        <div class="drag-container-body">
          <p class="mb-0">{{ activeTheory.description }}</p>
        </div>
      </template>
    </DragContainer>

  </div>
</template>

<script>
// @ is an alias to /src

import FindingsImage from "@/components/FindingsImage";
import FindingsList from "@/components/FindingsList";
import Funnel from "@/components/Funnel";
import EditProgress from "@/components/EditProgress";
import Tooltip from "@/components/Tooltip";
import NodeEditor from "@/components/NodeEditor";
import DragContainer from "@/components/DragContainer";
import LimbicMap from "@/components/LimbicMap";

export default {
  name: 'AuditsEdit',
  components: {
    LimbicMap,
    DragContainer,
    NodeEditor,
    Tooltip,
    EditProgress,
    Funnel,
    FindingsList,
    FindingsImage
  },
  data() {
    return {
      step: 1,
      editSteps: [
        "Journey / Seite auswählen",
        "Audit durchführen"
      ],
      extendedView: false,
      selectedMetric: "",
      newAudit: {
        id: "",
        theoryId: "",
        roadmapItemId: "",
        journeyId: "",
        title: "",
        summary: "",
        timestamp: +Date.now(),
        device: [],
        steps: []
      },
      selectedStepIndex: 0,
      highlightedFindingId: "",
      size: 'desktop',
      newMetric: {
        id: this.generateId(),
        title: ""
      },
      newAttribute: {
        id: this.generateId(),
        title: ""
      },
    }
  },
  computed: {
    nodes() {
      let map = [];
      map = map.concat(JSON.parse(JSON.stringify(this.project.netNodes)));
      map = map.concat(JSON.parse(JSON.stringify(this.project.externalNodes)));
      return map;
    },
    auditSteps() {
      return this.newAudit.steps.map(s => {
        s.node = this.findNode(s.nodeUrl);
        s.findings = this.project.findings.filter(f => f.auditId === this.newAudit.id && f.stepId === s.id)
        return s;
      });
    },
    auditJourney() {
      return this.project.journeys.find(j => j.id === this.newAudit.journeyId);
    },
    selectedStep() {
      return this.auditSteps[this.selectedStepIndex];
    },
    stepNode() {
      return this.findNode(this.selectedStep.nodeUrl);
    },
    stepFindings() {
      return this.project.findings.filter(f => f.auditId === this.newAudit.id && f.stepId === this.selectedStep.id)
    },
    stepJourney() {
      return this.auditJourney ? this.auditJourney.steps[this.selectedStepIndex] : null;
    },
    personas() {
      return this.project.personas.map(persona => {
        persona.jobs = persona.jobIds.map(jobId => this.project.jobs.find(j => j.id === jobId));
        return persona;
      });
    },
    pagetypes() {
      return this.project.settings.nodePagetypes || [];
    },
    attributes() {
      return this.project.settings.nodeAttributes || [];
    },
    metrics() {
      return this.project.settings.nodeMetrics || [];
    },
    funnelValues() {
      return this.auditJourney ? this.auditJourney.steps.map(s => s.metrics[this.selectedMetric.id] || 1) : null;
    },
    funnelTooltips() {
      return this.auditJourney ? this.auditJourney.steps.map(s => s.name) : null;
    },
  },
  methods: {
    selectStep(index) {
      this.selectedStepIndex = index;
      this.$forceUpdate();
    },
    removeStep(node) {
      let index = this.newAudit.steps.findIndex(step => step.nodeUrl === node.url);
      if (confirm(this.newAudit.steps[index].title + " wirklich löschen?")) {
        this.newAudit.steps.splice(index, 1);
        this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: this.project.findings})
            .then(res => {
              console.debug(res);
              this.$forceUpdate();
            });
      }
    },
    addFinding(finding) {
      finding.auditId = this.newAudit.id;
      finding.stepId = this.selectedStep.id;
      this.project.findings.push(finding);

      this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: this.project.findings})
          .then(res => {
            console.debug(res);
            this.saveData();
          });
    },
    editFinding(finding) {
      let index = this.project.findings.findIndex(f => f.id === finding.id);
      this.project.findings.splice(index, 1, finding);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: this.project.findings})
          .then(res => {
            console.debug(res);
            this.saveData();
          });
    },
    removeFinding(id) {
      let index = this.project.findings.findIndex(f => f.id === id);
      this.project.findings.splice(index, 1);

      this.$store.dispatch('project/updateProjectByProp', {prop: 'findings', data: this.project.findings})
          .then(res => {
            console.debug(res);
            this.saveData();
          });
    },
    highlightFinding(findingId, state) {
      this.highlightedFindingId = state ? findingId : "";
    },
    saveData() {
      let index = this.project.audits.findIndex(a => a.id === this.newAudit.id);
      if (index >= 0) {
        this.project.audits.splice(index, 1, this.newAudit);
        this.$store.dispatch('project/updateProjectByProp', {prop: 'audits', data: this.project.audits})
            .then(res => {
              console.debug(res);
              this.step = 1;
            });
      }
    },
    save() {
      if (this.$route.params.id) {
        let index = this.project.audits.findIndex(a => a.id === this.newAudit.id);
        this.project.audits.splice(index, 1, this.newAudit);
      } else {
        this.project.audits.push(this.newAudit);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'audits', data: this.project.audits})
          .then(res => {
            console.debug(res);
            this.$router.push('/audits');
          });
    },
  },
  beforeMount() {
    this.selectedMetric = this.metrics[0];
    let audit = this.project.audits.find(audit => audit.id === this.$route.params.id);
    if (audit) {
      this.newAudit = audit;
    } else {
      this.$router.push("/audits/add");
    }
  }
}
</script>

<style scoped lang="scss">
.drag-container {
  width: 250px;

  .drag-container-body {
    height: 100%;
    padding: 1rem;
    overflow-y: scroll;
    z-index: 9;
  }
}
.order-indicator {
  position: absolute;
  top: -1rem;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--dark);
  text-align: center;
  font-size: 1rem;
  padding: .25rem;

  &, a { color: var(--white); }
}
</style>