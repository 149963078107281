<template>
  <div v-show="loaded" :id="id" class="conversion-funnel">
    <!--<VueFunnelGraph :width="width" :height="height" :labels="labels"
                    :values="values" :colors="colors" :sub-labels="subLabels" :direction="direction"
                    :gradient-direction="gradientDirection"
                    :animated="true" :display-percentage="true"></VueFunnelGraph>-->

    <!--<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :width="width + 'px'"
         height="143.8px" :viewBox="'0 0 ' + width + ' 143.8'" style="overflow:visible;enable-background:new 0 0 207 143.8;" xml:space="preserve">
      <rect x="26.7" y="61.7" class="st0" width="153.6" height="20"/>
      <rect x="46.9" y="92.5" class="st0" width="113.3" height="20"/>
      <rect x="73.8" y="123.4" class="st0" width="59.4" height="20"/>
      <polygon class="st1" points="180.3,61.7 26.7,61.7 13,51.3 194,51.3 "/>
      <polygon class="st1" points="160.1,92.5 46.9,92.5 26.7,82.1 180.3,82.1 "/>
      <polygon class="st1" points="133.2,123.4 73.8,123.4 46.9,113 160.1,113 "/>
    </svg>-->
    <div v-for="(level, index) in levels" :key="index" class="funnel-level">
      <p v-if="tooltips" class="tooltip show">
        {{ tooltips[index] }}
      </p>
      <p v-if="showValues" class="absolute-value">
        {{ level.value }} <span class="font-weight-normal">({{ Math.round(level.relativeValue) }} %)</span>
      </p>
      <p v-if="showValues" class="relative-value">
        <i class="fal" :class="index < levels.length - 1 ? 'fa-arrow-down' : 'fa-bullseye'"></i>
        {{ level.dropOut > 0 ? ('+' + level.dropOut + '%') : level.dropOut ? (level.dropOut + '%') : '' }}
        <span v-if="index === levels.length - 1"> Total</span>
      </p>
      <svg :height="height + 'px'" :style="'overflow:visible;enable-background:new 0 0 ' + width + ' ' + height + ';'" :viewBox="'0 0 ' + width + ' ' + height"
           :width="width + 'px'" version="1.1" x="0px" xml:space="preserve"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink"
           y="0px">
      <polygon :fill="level.color" :points="level.svg"/>
        <polygon v-if="level.reduce" :fill="level.reduceColor" :points="level.reduce"/>
    </svg>
    </div>

  </div>
</template>

<script>
/*import { VueFunnelGraph } from 'vue-funnel-graph-js';*/

export default {
  name: 'Funnel',
  components: {
    /*VueFunnelGraph*/
  },
  directives: {},
  props: {
    values: Array,
    tooltips: Array,
    height: {
      type: Number,
      default: 76
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    showValues: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      id: "",
      loaded: false,
      width: 800
    }
  },
  computed: {
    levels() {
      const levels = [];
      const baselineValue = this.values.reduce((acc, v) => Math.max(acc, v));
      const outerWidth = this.width;
      const barHeight = this.height * 2 / 3;
      const reduceHeight = this.height / 3;

      for (let i = 0; i < this.values.length; i++) {
        let value = this.values[i] || 1;
        let width = value / baselineValue * outerWidth;
        let xOffset = (outerWidth - width) / 2;
        let yOffset = 0;
        let colorRGB = this.project.settings.themeColor;
        let color = this.pSBC(-i/this.values.length, colorRGB, "c");
        levels.push({
          value: value.toLocaleString(),
          relativeValue: (i === 0 ? 1 : value / this.values[0]) * 100,
          totalRelativeValue: (value / baselineValue),
          svg: (xOffset).toFixed() + "," + (yOffset).toFixed() + " " +
              (xOffset + width).toFixed() + "," + (yOffset).toFixed() + " " +
              (xOffset + width).toFixed() + "," + (yOffset + barHeight).toFixed() + " " +
              (xOffset).toFixed() + "," + (yOffset + barHeight).toFixed() + " ",
          color
        });
        let nextValue = this.values[i + 1];
        if (nextValue) {
          let lowerWidth = nextValue / baselineValue * outerWidth;
          let xOffsetLower = (outerWidth - lowerWidth) / 2;
          let reduceColor = this.pSBC(-i/this.values.length + .2, colorRGB, "c");
          levels[i].reduce = (xOffset).toFixed() + "," + (yOffset + barHeight).toFixed() + " " +
              (xOffset + width).toFixed() + "," + (yOffset + barHeight).toFixed() + " " +
              (xOffsetLower + lowerWidth).toFixed() + "," + (yOffset + barHeight + reduceHeight).toFixed() + " " +
              (xOffsetLower).toFixed() + "," + (yOffset + barHeight + reduceHeight).toFixed() + " ";
          levels[i].reduceColor = reduceColor;
          levels[i].dropOut = Math.round((nextValue / value - 1) * 10000) / 100;
        } else {
          levels[i].dropOut = Math.round((this.values[i] / this.values[0] - 1) * 10000) / 100;
        }
      }
      return levels;
    },
  },
  watch: {},
  methods: {},
  mounted() {
    this.id = this.generateId();
    const f = () => {
      let elWidth = document.getElementById(this.id).parentElement.offsetWidth
      this.width = this.fullWidth ? (elWidth - 30) : (elWidth * 2 / 3 - 20);
      this.loaded = true;
    }
    window.addEventListener('resize', f);
    setTimeout(f, 100);
  }
}
</script>

<style lang="scss" scoped>
.conversion-funnel {
  position: relative;
  padding-top: 5px;

  .funnel-level {
    width: 100%;
    max-width: 100%;
    position: relative;
    border-bottom: 1px solid #e0e0e0;
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    p {
      color: #888888;
      mix-blend-mode: difference;
    }

    .tooltip {
      top: .7rem;
      right: .5rem;
    }

    .absolute-value {
      position: absolute;
      top: .7rem;
      left: .5rem;
      font-weight: bold;
    }

    .relative-value {
      position: absolute;
      bottom: -.7rem;
      left: .5rem;
    }

    svg {
      transition: points .3s;
    }
  }
}
</style>

<!--
labels: ['Visits', 'Warenkörbe', 'Bestellungen', 'Umsatz', 'Gewinn'],
      subLabels: ['Direct', 'Social Media', 'Ads'],
      colors: [
        ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
        ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
        ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
      ],
      direction: 'horizontal',
      gradientDirection: 'horizontal',
      height: 300,
-->